import React, { useEffect, useState } from "react";
import { List, Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  AddCircleOutline,
  Delete,
  ExpandMore,
  GetApp,
  KeyboardArrowLeft,
  Print,
  Save,
} from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import NavigationPrompt from "../router/NavigationPrompt";
import Text, { DANGER, MUTED, PRIMARY, SUCCESS } from "../ui-core/Text";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import MenuButtonMore from "../ui-core/MenuButtonMore";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../data-list/DataList";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { IS_DOWNLOADING, IS_PRINTING } from "../orders-core/AdminPostForm";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getPDF, getPDF2, setInvalidate } from "../../helpers/FormUtils";
import {
  COURIER_MANIFEST_URL,
  CUSTOMS_INSPECTION_URL,
  DRIVER_MANIFEST_URL,
  FORM10_URL,
  FORM16_URL,
  FORM24_URL,
  HOLD_ON_CUSTOMS_URL,
  HYBRID_SIMPLE_URL,
  INVALIDATE_URL,
  MANIFEST_URL,
  RETURNED_MANIFEST_URL,
} from "../../api/admin/AdminBatchApi";
import { CONSOLIDATED, CONTAINER, SIMPLE } from "../../constants/OrderType";
import {
  DISPATCHED,
  RETURNED_TO_ORIGIN,
} from "../../constants/OrderStatusCodes";
import { TransitionUp } from "../dialog/TransitionUp";
import FormChipInput from "../form/FormChipInput";
import { api } from "../../api/shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";
import { formValues, reduxForm } from "redux-form";
import { hasUserPermission } from "../../reducers/ProfileReducer";
import { COLUMN, RENDER } from "../orders-core/MUITable";
import _ from "lodash";
import MUITableSimple from "../orders-core/MUITableSimple";
import { formatErrorMessage } from "../../helpers/FormatUtils";

const NSA = "NSA";
export const formatOrderMessage = (totalCount, getLocalisationMessage) =>
  totalCount > 1 && totalCount < 6
    ? getLocalisationMessage("orders_2", "orders")
    : totalCount === 1
    ? getLocalisationMessage("orders_1", "orders")
    : getLocalisationMessage("orders_3", "orders");

const enhancer = compose(
  connect(
    state => ({
      hasUserAddPermission: hasUserPermission(state, "BATCH_ITEM_ADD"),
      hasUserDeletePermission: hasUserPermission(state, "BATCH_ITEM_REMOVE"),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: props => props.theme.palette.primary1Color,
      },
    },
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  withState("state", "setState", {
    selectedRows: Set(),
    requestedClose: false,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const requestRefresh = createEventHandler();

        const responseStream = propsStream
          .distinctUntilKeyChanged("batchId")
          .switchMap(props =>
            props
              .getBatchOrderUpdateItem(props.batchId)
              .let(mapObjectResponseStream)
              .repeatWhen(() => requestRefresh.stream),
          )
          .scan((prev, next) =>
            next.get("pending") === false
              ? next
              : next.set("payload", prev.get("payload")),
          )
          .distinctUntilChanged(isEqualData)
          .map(response =>
            response.update(
              "payload",
              item =>
                item.isEmpty()
                  ? item
                  : item
                      // .update("order_numbers", OrderedSet)
                      .update("children", List),
              // .update("success_order_numbers", Set)
              // .update(
              //   "failed_orders",
              //   fp.flow(
              //     list => list || Map(),
              //     list =>
              //       list
              //         .toMap()
              //         .mapKeys((key, value) => value.get("order_number")),
              //   ),
              // ),
            ),
          );

        return propsStream.combineLatest(responseStream, (props, response) => ({
          ...props,
          item: response.get("payload"),
          errorMessage:
            response.get("error") && formatErrorMessage(response.get("error")),
          isLoading: response.get("pending"),
          onRequestRefresh: requestRefresh.handler,
        }));
      },
      propsStream => {
        const stateStream = propsStream
          .map(fp.pick(["state", "item"]))
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const { item, state } = props;
            const empty = item.isEmpty();

            const selectedRows = empty
              ? Set()
              : state.selectedRows.filter(
                  x =>
                    Boolean(
                      item.get("children").filter(l => l.get("id") === x).size,
                    ),
                  // item.hasIn(["order_numbers", x]),
                );

            const selectedFailedRows = selectedRows.filter(x =>
              item.hasIn(["failed_order_numbers", x]),
            );

            const selectedSuccessRows = selectedRows.filter(x =>
              item.hasIn(["success_order_numbers", x]),
            );

            const nsaOrders = empty
              ? Set()
              : item
                  .get("children")
                  .filter(x => x.get("status") === NSA)
                  .keySeq()
                  .toSet();

            const orderNumberList = empty
              ? List()
              : item.get("children").toList();

            return {
              ...state,

              nsaOrders,
              orderNumberList,

              selectedRows,
              selectedFailedRows,
              selectedSuccessRows,
            };
          });

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          state,
        }));
      },
    ),
  ),
  reduxForm({ form: "BatchUpdatesItemDialog", enableReinitialize: true }),
  formValues({ items: "items" }),
);

BatchUpdatesItemDialog.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  errorMessage: PropTypes.string,
  onRequestRefresh: PropTypes.func,
  refreshTabAndList: PropTypes.func,
  isRefresh: PropTypes.func,

  batchId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  refreshBatchList: PropTypes.func,
  items: PropTypes.array,
  hasUserAddPermission: PropTypes.bool,
  hideAddBatch: PropTypes.bool,
  hasUserDeletePermission: PropTypes.bool,
  isCustoms: PropTypes.bool,
  isHybrid: PropTypes.bool,
  isCanceledBatchId: PropTypes.bool,
};

function BatchUpdatesItemDialog(props) {
  const {
    item,
    errorMessage,
    state,
    classes,
    getLocalisationMessage,
    isLoading,
    isCustoms,
    isHybrid,
    isCanceledBatchId,
    hideAddBatch,
  } = props;
  const tempArr = item.get("warned_order_numbers", []);
  const warnedOrderNumbers = tempArr ? tempArr.map(v => ({ barcode: v })) : [];
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState(
    warnedOrderNumbers.length,
  );
  const [invalidateDialog, setInvalidateDialog] = useState(false);
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [stateHoldOnCustoms, setStateHoldOnCustoms] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: HOLD_ON_CUSTOMS_URL,
  });
  const [stateInspectionCustoms, setStateInspectionCustoms] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CUSTOMS_INSPECTION_URL,
  });
  const [stateHybridSimple, setStateHybridSimple] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: HYBRID_SIMPLE_URL,
  });
  const [stateManifest, setStateManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: MANIFEST_URL,
  });
  const [stateManifestNew, setStateManifestNew] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: MANIFEST_URL,
  });
  const [form10, setForm10] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM10_URL,
  });
  const [form16, setForm16] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM16_URL,
  });
  const [form24, setForm24] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: FORM24_URL,
  });
  const [stateInvalidate, setStateInvalidate] = useState({
    [IS_PRINTING]: false,
    url: INVALIDATE_URL,
  });
  const [driverManifest, setDriverManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: DRIVER_MANIFEST_URL,
  });
  const [courierManifest, setCourierManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: COURIER_MANIFEST_URL,
  });
  const [returnedManifest, setReturnedManifest] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RETURNED_MANIFEST_URL,
  });
  const children = item.isEmpty() ? [] : item.get("children");
  const batchStatus = item.get("status");
  const selectedType = item.get("type");
  const isBag = item.get("bag");
  const totalCount = children.size || 0;
  const failedList = children
    .filter(
      l =>
        item.get("asm_pro_status") === "FAILED" || l.get("status") === "FAILED",
    )
    .map(l => l.get("id"));
  const failedCount = failedList.size || 0;
  const succeedList = children
    .filter(
      l =>
        item.get("asm_pro_status") !== "FAILED" &&
        l.get("status") === "SUCCESS",
    )
    .map(l => l.get("id"));
  const succeedCount = succeedList.size || 0;
  const pendingCount = children.filter(l => l.get("status") === "PENDING").size;
  const pendingValue = ((succeedCount + failedCount) * 100) / totalCount;
  let refreshBatch;
  useEffect(() => {
    if (!isLoading && succeedCount + failedCount < totalCount)
      refreshBatch = setInterval(() => props.onRequestRefresh(), 1000);
    return () => clearInterval(refreshBatch);
  }, [isLoading]);

  useEffect(() => {
    if (totalCount > 0) {
      if (succeedCount + failedCount === totalCount) {
        if (props.isRefresh) {
          props.refreshTabAndList();
        }
      }
    }
  }, [succeedCount, failedCount, totalCount]);
  useEffect(() => {
    if (errorMessage) {
      props.showErrorMessage(errorMessage);
    }
  }, [errorMessage]);

  const buttons = isCanceledBatchId ? (
    <FlexBox>
      <ButtonGroup size="small" variant={CONTAINED} color={SECONDARY}>
        <CustomButton
          style={{ minWidth: "200px" }}
          data-cy="receipt_print"
          onClick={() =>
            getPDF(
              courierManifest,
              setCourierManifest,
              IS_PRINTING,
              props.batchId,
              props.showErrorMessage,
            )
          }
          endIcon={
            courierManifest[IS_PRINTING] ? (
              <CircularProgress size={20} color="white" />
            ) : (
              <Print />
            )
          }
        >
          {getLocalisationMessage("simple")}
        </CustomButton>
        <CustomButton
          data-cy="receipt_download"
          onClick={() =>
            getPDF(
              courierManifest,
              setCourierManifest,
              IS_DOWNLOADING,
              props.batchId,
              props.showErrorMessage,
            )
          }
        >
          {courierManifest[IS_DOWNLOADING] ? (
            <CircularProgress size={20} color="white" />
          ) : (
            <GetApp />
          )}
        </CustomButton>
      </ButtonGroup>
    </FlexBox>
  ) : isHybrid && selectedType === SIMPLE ? (
    <FlexBox>
      <ButtonGroup size="small" variant={CONTAINED} color={SECONDARY}>
        <CustomButton
          style={{ minWidth: "200px" }}
          data-cy="receipt_print"
          onClick={() =>
            getPDF(
              stateHybridSimple,
              setStateHybridSimple,
              IS_PRINTING,
              props.batchId,
              props.showErrorMessage,
            )
          }
          endIcon={
            stateHybridSimple[IS_PRINTING] ? (
              <CircularProgress size={20} color="white" />
            ) : (
              <Print />
            )
          }
        >
          {getLocalisationMessage("simple")}
        </CustomButton>
        <CustomButton
          data-cy="receipt_download"
          onClick={() =>
            getPDF(
              stateHybridSimple,
              setStateHybridSimple,
              IS_DOWNLOADING,
              props.batchId,
              props.showErrorMessage,
            )
          }
        >
          {stateHybridSimple[IS_DOWNLOADING] ? (
            <CircularProgress size={20} color="white" />
          ) : (
            <GetApp />
          )}
        </CustomButton>
      </ButtonGroup>
    </FlexBox>
  ) : isCustoms ? (
    <FlexBox>
      <ButtonGroup
        size="small"
        style={{ marginRight: ".5rem" }}
        variant={CONTAINED}
        color={SECONDARY}
      >
        <CustomButton
          onClick={() =>
            getPDF(
              stateHoldOnCustoms,
              setStateHoldOnCustoms,
              IS_PRINTING,
              props.batchId,
              props.showErrorMessage,
            )
          }
          endIcon={
            stateHoldOnCustoms[IS_PRINTING] ? (
              <CircularProgress size={20} color="white" />
            ) : (
              <Print />
            )
          }
        >
          {getLocalisationMessage("hold_on_at_customs")}
        </CustomButton>
        <CustomButton
          onClick={() =>
            getPDF(
              stateHoldOnCustoms,
              setStateHoldOnCustoms,
              IS_DOWNLOADING,
              props.batchId,
              props.showErrorMessage,
            )
          }
        >
          {stateHoldOnCustoms[IS_DOWNLOADING] ? (
            <CircularProgress size={20} />
          ) : (
            <GetApp />
          )}
        </CustomButton>
      </ButtonGroup>
      <ButtonGroup size="small" variant={CONTAINED} color={SECONDARY}>
        <CustomButton
          style={{ minWidth: "200px" }}
          data-cy="receipt_print"
          onClick={() =>
            getPDF(
              stateInspectionCustoms,
              setStateInspectionCustoms,
              IS_PRINTING,
              props.batchId,
              props.showErrorMessage,
            )
          }
          endIcon={
            stateInspectionCustoms[IS_PRINTING] ? (
              <CircularProgress size={20} color="white" />
            ) : (
              <Print />
            )
          }
        >
          {getLocalisationMessage("returned_from_customs")}
        </CustomButton>
        <CustomButton
          data-cy="receipt_download"
          onClick={() =>
            getPDF(
              stateInspectionCustoms,
              setStateInspectionCustoms,
              IS_DOWNLOADING,
              props.batchId,
              props.showErrorMessage,
            )
          }
        >
          {stateInspectionCustoms[IS_DOWNLOADING] ? (
            <CircularProgress size={20} color="white" />
          ) : (
            <GetApp />
          )}
        </CustomButton>
      </ButtonGroup>
    </FlexBox>
  ) : (
    <FlexBox>
      {selectedType === CONSOLIDATED ? (
        <FlexBox>
          <ButtonGroup
            style={{ marginRight: ".5rem" }}
            variant={CONTAINED}
            color={SECONDARY}
          >
            <CustomButton
              onClick={() =>
                getPDF2(
                  stateManifest,
                  setStateManifest,
                  IS_PRINTING,
                  props.batchId,
                  props.showErrorMessage,
                  false,
                )
              }
              endIcon={
                stateManifest[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("consolidated")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF2(
                  stateManifest,
                  setStateManifest,
                  IS_DOWNLOADING,
                  props.batchId,
                  props.showErrorMessage,
                  false,
                )
              }
            >
              {stateManifest[IS_DOWNLOADING] ? (
                <CircularProgress size={20} />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
          <ButtonGroup variant={CONTAINED} color={SECONDARY}>
            <CustomButton
              style={{ minWidth: "200px" }}
              data-cy="receipt_print"
              onClick={() =>
                getPDF(
                  form10,
                  setForm10,
                  IS_PRINTING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
              endIcon={
                form10[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("svodnik_yarlik")}
            </CustomButton>
            <CustomButton
              data-cy="receipt_download"
              onClick={() =>
                getPDF(
                  form10,
                  setForm10,
                  IS_DOWNLOADING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
            >
              {form10[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
        </FlexBox>
      ) : selectedType === SIMPLE ? (
        <FlexBox>
          {batchStatus === RETURNED_TO_ORIGIN ? (
            <ButtonGroup
              style={{ marginRight: "1rem" }}
              variant={CONTAINED}
              color={SECONDARY}
            >
              <CustomButton
                onClick={() =>
                  getPDF(
                    returnedManifest,
                    setReturnedManifest,
                    IS_PRINTING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  stateManifest[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("return")}
              </CustomButton>
              <CustomButton
                onClick={() =>
                  getPDF(
                    returnedManifest,
                    setReturnedManifest,
                    IS_PRINTING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
              >
                {stateManifest[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          ) : (
            <ButtonGroup
              style={{ marginRight: "1rem" }}
              variant={CONTAINED}
              color={SECONDARY}
            >
              <CustomButton
                onClick={() =>
                  getPDF(
                    stateManifest,
                    setStateManifest,
                    IS_PRINTING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  stateManifest[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("simple")}
              </CustomButton>
              <CustomButton
                onClick={() =>
                  getPDF(
                    stateManifest,
                    setStateManifest,
                    IS_DOWNLOADING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
              >
                {stateManifest[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          )}
          {isBag && (
            <ButtonGroup variant={CONTAINED} color={SECONDARY}>
              <CustomButton
                style={{ minWidth: "200px" }}
                data-cy="receipt_print"
                onClick={() =>
                  getPDF(
                    form10,
                    setForm10,
                    IS_PRINTING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  form10[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("svodnik_yarlik")}
              </CustomButton>
              <CustomButton
                data-cy="receipt_download"
                onClick={() =>
                  getPDF(
                    form10,
                    setForm10,
                    IS_DOWNLOADING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
              >
                {form10[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          )}
          {batchStatus === DISPATCHED && (
            <ButtonGroup variant={CONTAINED} color={SECONDARY}>
              <CustomButton
                style={{ minWidth: "200px" }}
                data-cy="receipt_print"
                onClick={() =>
                  getPDF(
                    driverManifest,
                    setDriverManifest,
                    IS_PRINTING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
                endIcon={
                  driverManifest[IS_PRINTING] ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
              >
                {getLocalisationMessage("driver_manifest")}
              </CustomButton>
              <CustomButton
                data-cy="receipt_download"
                onClick={() =>
                  getPDF(
                    driverManifest,
                    setDriverManifest,
                    IS_DOWNLOADING,
                    props.batchId,
                    props.showErrorMessage,
                  )
                }
              >
                {driverManifest[IS_DOWNLOADING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <GetApp />
                )}
              </CustomButton>
            </ButtonGroup>
          )}
        </FlexBox>
      ) : selectedType === CONTAINER ? (
        <FlexBox>
          <ButtonGroup
            style={{ marginRight: ".5rem" }}
            variant={CONTAINED}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF2(
                  stateManifestNew,
                  setStateManifestNew,
                  IS_PRINTING,
                  props.batchId,
                  props.showErrorMessage,
                  true,
                )
              }
              endIcon={
                stateManifestNew[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage(
                "batch_of_registers",
                "batch of registers",
              )}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF2(
                  stateManifestNew,
                  setStateManifestNew,
                  IS_DOWNLOADING,
                  props.batchId,
                  props.showErrorMessage,
                  true,
                )
              }
            >
              {stateManifestNew[IS_DOWNLOADING] ? (
                <CircularProgress size={20} />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
          <ButtonGroup
            style={{ marginRight: "1rem" }}
            variant={CONTAINED}
            color={SECONDARY}
          >
            <CustomButton
              onClick={() =>
                getPDF(
                  stateManifest,
                  setStateManifest,
                  IS_PRINTING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
              endIcon={
                stateManifest[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("container")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  stateManifest,
                  setStateManifest,
                  IS_DOWNLOADING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
            >
              {stateManifest[IS_DOWNLOADING] ? (
                <CircularProgress size={20} />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
        </FlexBox>
      ) : (
        <FlexBox>
          <ButtonGroup
            variant={CONTAINED}
            style={{ marginRight: "1rem" }}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF(
                  form16,
                  setForm16,
                  IS_PRINTING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
              endIcon={
                form16[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("route_nakladnoy")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  form16,
                  setForm16,
                  IS_DOWNLOADING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
            >
              {form16[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
          <ButtonGroup
            variant={CONTAINED}
            style={{ marginRight: "1rem" }}
            color={SECONDARY}
          >
            <CustomButton
              style={{ minWidth: "200px" }}
              onClick={() =>
                getPDF(
                  form24,
                  setForm24,
                  IS_PRINTING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
              endIcon={
                form24[IS_PRINTING] ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  <Print />
                )
              }
            >
              {getLocalisationMessage("forma24")}
            </CustomButton>
            <CustomButton
              onClick={() =>
                getPDF(
                  form24,
                  setForm24,
                  IS_DOWNLOADING,
                  props.batchId,
                  props.showErrorMessage,
                )
              }
            >
              {form24[IS_DOWNLOADING] ? (
                <CircularProgress size={20} color="white" />
              ) : (
                <GetApp />
              )}
            </CustomButton>
          </ButtonGroup>
        </FlexBox>
      )}
    </FlexBox>
  );
  const handleAdd = () => {
    setIsLoadingAdd(true);
    api
      .put(`${API_ROOT_URL}/admin/batch_update/${props.batchId}/add_items`, {
        body: { items: props.items, hybrid: isHybrid },
      })
      .then(res => {
        if (res && res.status === "success") {
          props.onRequestRefresh();
          setIsOpenAddForm(false);
          props.showSuccessMessage("successfully");
        } else {
          props.showErrorMessage("something_wrong");
        }
      })
      .catch(error => props.showErrorMessage(error))
      .finally(() => setIsLoadingAdd(false));
  };
  const handleDelete = id => {
    setIsLoadingDelete(true);
    api
      .put(`${API_ROOT_URL}/admin/batch_update/${props.batchId}/remove_items`, {
        body: { items: id ? [id] : toJS(state.selectedRows) },
      })
      .then(res => {
        if (res && res.status === "success") {
          props.onRequestRefresh();
          props.showSuccessMessage("successfully");
          setIsLoadingDelete(false);
        } else {
          props.showErrorMessage("something_wrong");
        }
      })
      .catch(error => props.showErrorMessage(error))
      .finally(() => setIsLoadingDelete(false));
  };
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={true}
      modal={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      bodyClassName={classes.body}
      contentClassName={classes.contentStyle}
      className={classes.rootStyle}
    >
      <Dialog
        open={isOpenErrorDialog}
        maxWidth="md"
        onClose={props.onRequestClose}
      >
        <DialogTitle
          style={{ color: props.theme.palette.appBarTextColor }}
          className={classes.dialogTitle}
        >
          <div className={classes.dialogHeaderContainer}>
            <div className={classes.dialogHeaderTitle}>
              {getLocalisationMessage("warning")} !!!
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <FlexBox direction="column">
            <h5 style={{ marginBottom: "1rem" }}>
              {getLocalisationMessage(
                "these_orders_not_belongs_to_your_warehouse_please_check_carefully",
              )}
            </h5>
            <MUITableSimple
              size="small"
              list={warnedOrderNumbers}
              columns={[
                {
                  type: COLUMN,
                  name: "barcode",
                  label: getLocalisationMessage("barcode"),
                },
              ]}
            />
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <FlexBox
            style={{ margin: "1rem" }}
            flex={true}
            justify={JUSTIFY_CENTER}
          >
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              startIcon={<KeyboardArrowLeft />}
              primary={true}
              onClick={() => {
                setIsOpenErrorDialog(false);
              }}
            >
              {getLocalisationMessage("close_window")}
            </CustomButton>
          </FlexBox>
        </DialogActions>
      </Dialog>
      <form>
        <Dialog
          TransitionComponent={TransitionUp}
          open={isOpenAddForm}
          fullWidth={true}
          maxWidth="lg"
          onClose={() => setIsOpenAddForm(false)}
        >
          <DialogTitle style={{ borderBottom: "1px solid #eeeeee" }}>
            {getLocalisationMessage("edit")}
          </DialogTitle>
          <DialogContent>
            <FlexBox direction="column" flex={true}>
              <FormChipInput
                name="items"
                focus={true}
                fullWidth={true}
                addOnBlur={true}
                label={getLocalisationMessage("orders_numbers")}
              />
            </FlexBox>
          </DialogContent>
          <DialogActions>
            <FlexBox
              flex={true}
              justify={JUSTIFY_CENTER}
              style={{ paddingBottom: "1rem" }}
            >
              <FlexBox>
                <CustomButton
                  onClick={() => setIsOpenAddForm(false)}
                  startIcon={<KeyboardArrowLeft />}
                  variant={OUTLINED}
                  color={SECONDARY}
                >
                  {getLocalisationMessage("close_window", "Close window")}
                </CustomButton>
                <CustomButton
                  style={{ marginLeft: "1rem" }}
                  onClick={handleAdd}
                  endIcon={
                    isLoadingAdd ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <Save />
                    )
                  }
                  variant={OUTLINED}
                  color={SECONDARY}
                >
                  {getLocalisationMessage("save")}
                </CustomButton>
              </FlexBox>
            </FlexBox>
          </DialogActions>
        </Dialog>
        <DialogTitle
          style={{ color: props.theme.palette.appBarTextColor }}
          className={classes.dialogTitle}
        >
          <FlexBox align="center" direction="row">
            <FlexBox flex={true} gutter={8}>
              {`${getLocalisationMessage("batch_update", "Batch Update")} #${
                props.batchId
              }`}
            </FlexBox>

            <FlexBox>
              {2 === 3 && props.refreshBatchList && (
                <MenuButtonMore>
                  <MenuItem onClick={() => setInvalidateDialog(true)}>
                    {getLocalisationMessage("invalidate", "Invalidate")}
                  </MenuItem>
                </MenuButtonMore>
              )}
            </FlexBox>
          </FlexBox>
        </DialogTitle>
        <NavigationPrompt
          when={pendingCount > 0 && !state.requestedClose}
          message={getLocalisationMessage(
            "there_are_pending_updates",
            "There are pending updates, are you sure you want to exit?",
          )}
        />

        <ConfirmDialog
          modal={true}
          confirmButtonLabel={getLocalisationMessage("exit", "Exit")}
          dismissButtonLabel={getLocalisationMessage("go_back", "Go Back")}
          onConfirm={() => props.onRequestClose(item)}
          open={pendingCount > 0 && state.requestedClose}
          onRequestClose={() => props.setState(fp.set("requestedClose", false))}
        >
          {getLocalisationMessage("there_are_pending_updates")}
        </ConfirmDialog>
        {props.refreshBatchList && (
          <ConfirmDialog
            modal={true}
            confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
            dismissButtonLabel={getLocalisationMessage("back", "Back")}
            onConfirm={() => {
              setInvalidate(
                stateInvalidate,
                setStateInvalidate,
                IS_PRINTING,
                [props.batchId],
                props.showErrorMessage,
                props.showSuccessMessage,
                props.refreshBatchList,
              );
              setTimeout(() => {
                setInvalidateDialog(false);
                if (pendingCount > 0) {
                  props.setState(fp.set("requestedClose", true));
                } else {
                  props.onRequestClose(item);
                }
              }, 250);
            }}
            open={invalidateDialog}
            onRequestClose={() => setInvalidateDialog(false)}
          >
            {getLocalisationMessage(
              "are_you_sure_you_want_to_invalidate_the_batch",
              "Are you sure you want to invalidate the batch",
            )}
          </ConfirmDialog>
        )}
        <DialogContent>
          <FlexBox container={24} flex={true} direction="column">
            <FlexBox
              gutter={8}
              justify="space-between"
              className={classes.wrapContent}
            >
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("status", "Status")}
                  secondary={formatOrderStatusCodeForLocalisation(
                    item.get("status"),
                    getLocalisationMessage,
                  )}
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("created_by", "Created By")}
                  secondary={item.getIn(
                    ["user", "name"],
                    item.getIn(["user", "description"]),
                  )}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("type", "Type")}
                  secondary={getLocalisationMessage(item.get("type"))}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("category", "Category")}
                  secondary={
                    item.get("category")
                      ? getLocalisationMessage(
                          item.get("category").toLowerCase(),
                        )
                      : "-"
                  }
                />
              </FlexBox>
            </FlexBox>

            <FlexBox
              gutter={8}
              justify="flex-end"
              className={classes.wrapContent}
            >
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage(
                    "from_warehouse",
                    "Warehouse",
                  )}
                  secondary={item.getIn(["from_warehouse", "name"]) || "-"}
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("next_warehouse")}
                  secondary={item.getIn(["next_warehouse", "name"]) || "-"}
                />
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={getLocalisationMessage("to_warehouse")}
                  secondary={item.getIn(["to_warehouse", "name"]) || "-"}
                />
              </FlexBox>

              <FlexBox direction="column" flex={true}>
                <ListItemText
                  disabled={true}
                  primary={`Общий вес: ${item.get("weight") || 0}  кг`}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox gutter={8} justify="flex-end">
              <FlexBox
                direction="row"
                justify="space-between"
                className={classes.wrapContent}
                gutter={8}
              >
                <FlexBox align="center">
                  <ListItemText
                    disabled={true}
                    primary={getLocalisationMessage("succeed_short", "Succeed")}
                    secondary={
                      <Text type={SUCCESS} element="div">
                        {succeedCount}{" "}
                        {formatOrderMessage(
                          succeedCount,
                          getLocalisationMessage,
                        )}
                      </Text>
                    }
                  />
                </FlexBox>

                <FlexBox align="center">
                  <ListItemText
                    disabled={true}
                    primary={getLocalisationMessage("failed_short", "Failed")}
                    secondary={
                      <Text type={DANGER} element="div">
                        {failedCount}{" "}
                        {formatOrderMessage(
                          failedCount,
                          getLocalisationMessage,
                        )}
                      </Text>
                    }
                  />
                </FlexBox>

                <FlexBox>
                  <ListItemText
                    disabled={true}
                    primary={getLocalisationMessage("total", "Total")}
                    secondary={
                      <Text type={PRIMARY} element="div">
                        {totalCount}{" "}
                        {formatOrderMessage(totalCount, getLocalisationMessage)}
                      </Text>
                    }
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>

            {pendingCount > 0 && (
              <FlexBox flex={true} align={ALIGN_CENTER} direction="column">
                <CircularProgress color="secondary" />
              </FlexBox>
            )}
            {pendingCount > 0 && (
              <LinearProgress
                color="secondary"
                variant="determinate"
                value={pendingValue}
                style={{ height: ".5rem" }}
              />
            )}
            <DataList
              isLoading={false}
              overscanRowCount={10}
              rowCount={totalCount}
              totalCount={totalCount}
              rowGetter={row => state.orderNumberList.get(row.index)}
              altHeader={
                <FlexBox align="center">
                  {!isCanceledBatchId && (
                    <div style={{ position: "absolute", left: 0, top: 15 }}>
                      {state.selectedRows.isEmpty() ? (
                        <React.Fragment>
                          {!hideAddBatch && props.hasUserAddPermission && (
                            <CustomButton
                              style={{ marginRight: ".5rem" }}
                              variant={OUTLINED}
                              color={SECONDARY}
                              onClick={() => setIsOpenAddForm(true)}
                              endIcon={<AddCircleOutline />}
                            >
                              {getLocalisationMessage("add")}
                            </CustomButton>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {props.hasUserDeletePermission && (
                            <CustomButton
                              variant={OUTLINED}
                              color={SECONDARY}
                              onClick={() => handleDelete()}
                              endIcon={
                                isLoadingDelete ? (
                                  <CircularProgress
                                    size={20}
                                    color="secondary"
                                  />
                                ) : (
                                  <Delete />
                                )
                              }
                            >
                              {getLocalisationMessage("delete")}
                            </CustomButton>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                  {pendingCount < 1 && buttons}
                </FlexBox>
              }
            >
              {pendingCount === 0 && (
                <DataListCheckbox
                  allRowsSelected={() =>
                    state.selectedRows.size > 0 &&
                    state.selectedRows.size === totalCount
                  }
                  onAllRowsSelect={value =>
                    props.setState(
                      fp.set(
                        "selectedRows",
                        value
                          ? item
                              .get("children")
                              .map(l => l.get("id"))
                              .toSet()
                          : Set(),
                      ),
                    )
                  }
                  rowSelected={row =>
                    state.selectedRows.has(
                      row.cellData && row.cellData.get("id"),
                    )
                  }
                  onRowSelect={row => {
                    props.setState(
                      fp.set(
                        "selectedRows",
                        row.selected
                          ? state.selectedRows.add(row.cellData.get("id"))
                          : state.selectedRows.delete(row.cellData.get("id")),
                      ),
                    );
                  }}
                />
              )}
              <DataListColumn
                label="#"
                dataKey="index"
                disableSort={true}
                cellRenderer={row => row.rowIndex + 1}
              />

              <DataListColumn
                disableSort={true}
                label={getLocalisationMessage("barcode")}
                dataKey="order_number"
                cellRenderer={row =>
                  row.cellData && row.cellData.get("barcode")
                }
              />

              <DataListColumn
                disableSort={true}
                label={getLocalisationMessage("weight", "Weight")}
                dataKey="weight"
                cellRenderer={row =>
                  row.cellData ? row.cellData.get("weight") || 0 : 0
                }
              />

              <DataListColumn
                disableSort={true}
                label={getLocalisationMessage("status", "Status")}
                dataKey="status"
                cellRenderer={row =>
                  succeedList.contains(
                    row.cellData && row.cellData.get("id"),
                  ) ? (
                    <Text type={SUCCESS}>
                      {getLocalisationMessage("success", "Success")}
                    </Text>
                  ) : failedList.contains(
                      row.cellData && row.cellData.get("id"),
                    ) ? (
                    <Text type={DANGER}>
                      {getLocalisationMessage("failed", "Failed")}
                    </Text>
                  ) : (
                    <Text type={MUTED}>
                      {getLocalisationMessage("pending", "Pending")}
                    </Text>
                  )
                }
              />
              {failedCount > 0 && (
                <DataListColumn
                  disableSort={true}
                  label={getLocalisationMessage("error_status", "Error Status")}
                  dataKey="failed_orders"
                  cellRenderer={row => (
                    <Text
                      type="danger"
                      fallbackValue={getLocalisationMessage("na", "N/A")}
                      fallbackType="muted"
                    >
                      {getLocalisationMessage(
                        row.cellData && row.cellData.get("status") !== "SUCCESS"
                          ? _.toLower(row.cellData.get("message_code"))
                          : "-",
                      )}
                    </Text>
                  )}
                />
              )}
              {props.hasUserDeletePermission && (
                <DataListColumn
                  justifyContent={JUSTIFY_CENTER}
                  dataKey="delete"
                  label={getLocalisationMessage("delete")}
                  cellRenderer={row => (
                    <Delete
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(row.cellData.get("id"))}
                    />
                  )}
                />
              )}
            </DataList>
            {item.get("manual_items") && item.get("manual_items").size > 0 && (
              <FlexBox direction="column">
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.heading}>
                      {getLocalisationMessage("view_rules")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MUITableSimple
                      size="small"
                      list={toJS(item.get("manual_items"))}
                      columns={[
                        {
                          type: RENDER,
                          name: "category",
                          label: getLocalisationMessage("category"),
                          render: row =>
                            getLocalisationMessage(
                              _.get(row, `category.name`, "-"),
                            ),
                        },
                        {
                          type: COLUMN,
                          name: "qty",
                          label: getLocalisationMessage("quantity"),
                        },
                        {
                          type: COLUMN,
                          name: "amount",
                          label: getLocalisationMessage("amount_bag"),
                        },
                        {
                          type: COLUMN,
                          name: "weight",
                          label: getLocalisationMessage("weight"),
                        },
                        {
                          type: RENDER,
                          name: "from_warehouse",
                          label: getLocalisationMessage("from_warehouse"),
                          render: row => _.get(row, `from_warehouse.name`, ""),
                        },
                        {
                          type: RENDER,
                          name: "to_warehouse",
                          label: getLocalisationMessage("to_warehouse"),
                          render: row => _.get(row, `to_warehouse.name`, ""),
                        },
                        {
                          type: COLUMN,
                          name: "note",
                          label: getLocalisationMessage("note"),
                        },
                      ]}
                    />
                  </AccordionDetails>
                </Accordion>
              </FlexBox>
            )}
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button
            primary={true}
            onClick={() => {
              if (pendingCount > 0) {
                props.setState(fp.set("requestedClose", true));
              } else {
                props.onRequestClose(item);
              }
            }}
          >
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default enhancer(BatchUpdatesItemDialog);
